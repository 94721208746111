@import url('../fonts/stylesheet.css');

:root {
  --main:#16141a;
  --black: #000000;
  --white: #FFFFFF;
  --blue: #00D2FF;
  --green: #16e7cf;
  --purple: #b300de;
}

body {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  background-color: var(--main);
  color: white;
}


a:active, a:hover, a {
  text-decoration: none;
  color: var(--white);
}

.container {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}


@import url('Header.css');

@import url('Footer.css');

.playerPage, .businessPage {
  display: flex;
  justify-content: space-around;
  margin: 150px 0;
}

canvas {
  width: 55%;
  height: 75%;
}

@media (max-width:767px) {
  canvas {
      position: absolute;
      height: auto;
      width: auto;
      overflow: hidden;
      z-index: -1;
      top: 10%;
  }

}

.playerPage .entryForm,
.businessPage .entryForm {
  padding: 10px;
  border-radius: 10px;
  font-size: 22px;
  line-height: 32px;
  width: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input-box{
  position: relative;
  background-color: var(--light);
  margin-top: 285px;
  width: 757px;
  min-height: 532px;
  display: flex;
  padding: 35px;
  transition: none;
  border-radius: 25px;
  vertical-align: middle;
  align-items: center;  
  justify-content: center;
  font-size: 24px;
  line-height: 38px;
}