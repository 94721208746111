.general-terms h1 {
    color:hsla(0, 0%, 100%, 0.8);
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.general-terms p {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 16px;
    font-family: 'Avenir', sans-serif;
    font-weight: 300;
}

.general-terms span{
    font-weight: 700;
}

.general-terms h2 {
    margin-bottom: 24px;
    color: hsla(0, 0%, 100%, 0.8);
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .container {
        width: 70%;
        margin-top: 130px;
    }
    .general-terms h2 {
        font-size: 24px;
    }
    .general-terms h1 {
        font-size: 32px;
    }
    .general-terms p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .general-terms p {
        font-size: 16px;
    }
    .general-terms h2 {
        font-size: 22px;
    }
    .general-terms h1 {
        font-size: 22px;
    }
}