@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Roman.eot');
    src: local('Avenir Roman'), local('Avenir-Roman'),
        url('Avenir-Roman.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Roman.woff2') format('woff2'),
        url('Avenir-Roman.woff') format('woff'),
        url('Avenir-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Light.eot');
    src: local('Avenir Light'), local('Avenir-Light'),
        url('Avenir-Light.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Light.woff2') format('woff2'),
        url('Avenir-Light.woff') format('woff'),
        url('Avenir-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('Avenir-BookOblique.eot');
    src: local('Avenir Book Oblique'), local('Avenir-BookOblique'),
        url('Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-BookOblique.woff2') format('woff2'),
        url('Avenir-BookOblique.woff') format('woff'),
        url('Avenir-BookOblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Black Oblique';
    src: url('Avenir-BlackOblique.eot');
    src: local('Avenir Black Oblique'), local('Avenir-BlackOblique'),
        url('Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-BlackOblique.woff2') format('woff2'),
        url('Avenir-BlackOblique.woff') format('woff'),
        url('Avenir-BlackOblique.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Heavy.eot');
    src: local('Avenir Heavy'), local('Avenir-Heavy'),
        url('Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Heavy.woff2') format('woff2'),
        url('Avenir-Heavy.woff') format('woff'),
        url('Avenir-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-HeavyOblique.eot');
    src: local('Avenir Heavy Oblique'), local('Avenir-HeavyOblique'),
        url('Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-HeavyOblique.woff2') format('woff2'),
        url('Avenir-HeavyOblique.woff') format('woff'),
        url('Avenir-HeavyOblique.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-MediumOblique.eot');
    src: local('Avenir Medium Oblique'), local('Avenir-MediumOblique'),
        url('Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-MediumOblique.woff2') format('woff2'),
        url('Avenir-MediumOblique.woff') format('woff'),
        url('Avenir-MediumOblique.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-LightOblique.eot');
    src: local('Avenir Light Oblique'), local('Avenir-LightOblique'),
        url('Avenir-LightOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-LightOblique.woff2') format('woff2'),
        url('Avenir-LightOblique.woff') format('woff'),
        url('Avenir-LightOblique.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('Avenir-Book.eot');
    src: local('Avenir Book'), local('Avenir-Book'),
        url('Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Book.woff2') format('woff2'),
        url('Avenir-Book.woff') format('woff'),
        url('Avenir-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Black.eot');
    src: local('Avenir Black'), local('Avenir-Black'),
        url('Avenir-Black.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Black.woff2') format('woff2'),
        url('Avenir-Black.woff') format('woff'),
        url('Avenir-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Oblique.eot');
    src: local('Avenir Oblique'), local('Avenir-Oblique'),
        url('Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Oblique.woff2') format('woff2'),
        url('Avenir-Oblique.woff') format('woff'),
        url('Avenir-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Medium.eot');
    src: local('Avenir Medium'), local('Avenir-Medium'),
        url('Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Medium.woff2') format('woff2'),
        url('Avenir-Medium.woff') format('woff'),
        url('Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

