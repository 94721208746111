header {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: auto;
    display: block;
    margin-bottom: 0;
    font-family: 'Avenir', sans-serif;
    background-color: var(--main);
    z-index: 999;
}

.header-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}


.items {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: stretch;
}

.logo {
    display: flex;
    align-items: center;
    -webkit-box-align: center;
}

.logo a {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.logo img {
    margin-left: 10px;
    width: 30px;
    height: 30px;
}

nav {
    position: static;
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    align-self: auto;
    flex: 0 auto;
    float: right;
    transition: 0.5s ease all;
}

.nav-item {
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    color: var(--white);
    font-size: 16px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    transition: all ease 0.3s;
}

.nav-item:hover {
    color: var(--green);
}

.login-area, .signUp-area {
    display: inline-block;
    transition: all ease 0.3s;
}

.login-area a {
    border-radius: 3px;
    background-color: var(--white);
    color: var(--main);
}

.login-area a:hover {
    background-color: var(--green);
    color: var(--main);
}

.signUp-area a {
    border-style: solid;
    border-width: 1px;
    border-color: var(--white);
    border-radius: 3px;
}

.signUp-area a:hover {
    border-color: var(--green);
}

.navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 23px; 
    background-color: var(--main);
    display: none;
}

.navbar-toggler:hover {
    background-color: var(--main);
}

.navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1; 
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0; 
}

.navbar-toggler[aria-expanded="true"] span:first-child {
    transform: rotate(-45deg);
    position: relative;
    top: 7.5px; 
}

.navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(45deg);
    bottom: 6px;
    position: relative; 
}

.navbar-toggler span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer; 
}

@media (max-width: 1619px) {
    .header-container {
        max-width: 900px;
    }
}

@media (max-width: 1199px) {
    .header-container {
        max-width: 700px;
    }
}

@media (max-width: 767px) {
    .header-container {
        width: auto;
        margin: 0 30px;
    }
    .nav-menu {
        display: none;
    }
    .items {
        flex-direction: row-reverse;
    }
    .navbar-toggler {
        display: block;
    }
    nav[aria-expanded="true"] {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        position: fixed;
        top: 111px;
        left: 0;
        width: 100%;
        background-color: var(--main);
    }
    nav[aria-expanded="true"] a {
        margin: 10px 0;
        font-size: 18px;
    }
    .logo a {
        font-size: 22px;
    }
}

@media (max-width: 575px) {
    nav[aria-expanded="true"] a, nav[aria-expanded="true"] p {
        font-size: 16px;
    }
}
