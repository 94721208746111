
.businessPage .entryForm {
    color: var(--white);
}

.businessPage form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;    
    width: 500px;
}

.businessPage h1{
    line-height: 28px;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}

.businessPage form .title {
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 5px;
    position: relative;
    margin-top: 0;
}

.businessPage .area{
    margin-bottom: 20px;
}

input{
    display: block;
    height: 38px;
    padding: 0 0.75rem;
    width: 100%;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5;
    color: black;
    background-clip: padding-box;
    border: 2px solid #BBBBBB;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input::placeholder {
    color: black;
    opacity: 0.4;
}

input:focus {
    color: black;
    border-color: var(--green);
    outline: 0;
}

input:disabled,
input[readonly] {
    background-color: #f5f5f5;
    opacity: 1;
}

.SendButton {
    display: inline-block;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--white);
    padding: 0;
    border: none;
}

.SendButton p {
    border-radius: 3px;
    color: var(--main);
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

button:hover{
    background-color: var(--green);
}

#upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    min-width: 240px;
    border-radius: 3px;
    background-color: var(--white);
}

#upload-container p{
    color: var(--main);
    padding: 8px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

#upload-container svg {
    position: relative;
    fill: var(--main);
    max-width: 40px;
    max-height: 40px;
}

#upload-container img {
    position: relative;
    max-width: 30px;
    max-height: 30px;
}

#upload-container:hover {
    background-color: var(--green);
}

#upload-container input[type=file] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -10;
}

.upload, .btn {
    display: flex;
    justify-content: center;
}



.slider {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.slider-item {
    width: 60px;
    height: 70px;
    line-height: 70px;
    margin: auto 15px;
    margin-top: 5px;
    background-size: cover;
    float: left;
    flex-grow: 0;
    flex-shrink:0;
}

.item-img {
    width: 53px;
    height: 44px;
    position: relative;
    background-image: url("../images/CSGOlogo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
}

.item-fileName {
    position: relative;
    font-size: 10px;
    line-height: 24px;
    max-width: 80px;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 1px;
}

.slider-area {  
    display: flex;
    overflow-y: hidden;
}

.item-img .cross svg {
    position: absolute;
    right: -3px;
    top: -7px;
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;
}

.message {
    color: var(--green);
    line-height: 1.5;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width: 1619px) {
    .businessPage .entryForm {
        width: 400px;
    }
    .slider {
        max-width: 360px;
    }
}

@media (max-width: 991px) {
    .businessPage form .title {
        font-size: 16px;
    }
    #upload-container p {
        font-size: 14px;
    }
    #upload-container {
        min-width: 200px;
    }
    input {
        width: 300px;
    }
    .slider {
        margin: 0;
        max-width: 320px;
    }
    canvas {
        margin: auto 0;
    }
    .businessPage {
        margin-top: 170px;
    }
    .businessPage .entryForm {
        width: 320px;
        margin: 30px;
        padding: 0;
    }
    .businessPage form {
        padding: 0;
    }
    .businessPage .entryForm .Success{
        font-size: 16px;
        line-height: 1.5;
    }

}

@media (max-width: 767px) {
    .businessPage .entryForm .Success{
        text-align: center;
        margin: 80px 0;
    }
}

@media (max-width: 480px) {
    .businessPage form {
        width: 270px;
    }
    input {
        width: 240px;
    }
}