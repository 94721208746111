.playerPage .entryForm {
    flex-direction: column;
}

.playerPage .Registration {
    display: flex;
    flex-direction: row;
}

.playerPage .Success {
    max-width: 250px;
    max-height: 48px;
    margin: 0 10px;
}

.playerPage .Success p {
    margin: 0;
    line-height: 48px;
}

.playerPage h1 {
    line-height: 36px;
    font-size: 24px;
    text-align: center;
}

.playerPage p {
    text-align: center;
}

.Registration .btn {
    display: inline-block;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 3px;
    background-color: var(--white);
}

.Registration .btn svg {
    position: absolute;
    top: 0;
    left: 8px;
    fill: var(--main);
    max-width: 40px;
    max-height: 50px;
}

.Registration .btn a {
    border-radius: 3px;
    color: var(--main);
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    padding-left: 46px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.Registration .btn:hover {
    background-color: var(--purple);
}

@media (max-width: 1199px) {
    .playerPage .Registration {
        flex-direction: column;
    }
    .Registration .btn {
        margin-top: 20px;
    }
    .playerPage h1 {
        margin-bottom: 0;

    }
}

@media (max-width: 767px) {
    .playerPage {
        margin: 30% 0;
    }
}

@media (max-width: 575px) {
    .playerPage {
        margin: 40% 0;
    }
}

@media (max-width: 480px) {
    .playerPage h1 {
        font-size: 22px;
    }
}