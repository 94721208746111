.about-section .container {
    padding-bottom: 128px;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-section h1 {
    margin-bottom: 48px;
    font-size: 38px;
    line-height: 44px;
    margin-top: 20px;
    font-weight: bold;
}

.about-section .svg-section {
    display: flex;
    margin-bottom: 36px;
    justify-content: center;
    align-items: center;
    max-height: 30px;
}

.about-section .svg-section div {
    height: 30px;
    margin-right: 8px;
}

.about-section .svg-section svg {
    max-height: 30px;
    max-width: 30px;
    fill: #676a6c;
}

.about-section .plus svg, 
.about-section .equally svg {
    max-width: 20px;
    max-height: 20px;
    padding-top: 5px;
}

.about-section .container h2 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 400;
}

.about-section .container p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 40px;
}

.about-section .maps-container {
    margin-bottom: 0px;
}

.about-section .maps-container .adress {
    padding: 16px 48px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.adress .logo {
    font-size: 22px;
    font-weight: normal;
}

.adress p {
    font-size: 18px;
    font-weight: 400;
}

.adress .mail {
    color: var(--green);
    font-size: 18px;
    text-decoration: none;
}

@media (max-width: 767px) {
    .about-section .container {
        padding-bottom: 0;
        width: 70%;
    }
    .maps-container {
        flex-direction: column;
    }
    .about-section h1 {
        font-size: 36px;
    }
    .adress p {
        font-size: 16px;
        margin: 0;
        line-height: 1.5;
    }
}

@media (max-width: 575px) {
    .about-section .container h2 {
        font-size: 24px;
    }
    .about-section h1  {
        font-size: 32px;
    }
}

@media (max-width: 480px) {
    .adress {
        flex-direction: column;
    }
    .adress p {
        margin: 10px 0;
        font-size: 14px;
    }
    .about-section .container h2 {
        font-size: 22px;
        font-weight: 700;
    }
}
