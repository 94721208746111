#canvasOne {
    width: 100vw;/*calc(100vh * 0.5625 * 0.8*/
    height: 80vw;
    max-height: 800px;
    max-width: 1180px;
    position: absolute;
    margin-top: 50px;
    left: 50%;
    transform: translate(-50%);
    top: 0;
    z-index: -1;
}

.homePages {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.homePages .container {
    margin-top: 20%;
    margin-bottom: 31%; 
}

.homePages h1 {
    margin-bottom: 32px;
    font-size: 50px;
    text-align: center;
    line-height: 1.5;
    margin-top: 20px;
}

.homePages h2 {
    margin-top: 16px;
    margin-bottom: 64px;
    font-size: 24px;
    text-align: center;
    line-height: 1.5;
}

.homePages .btn-area {
    display: flex;
    justify-content: center;
}

.homePages a {
    padding: 16px 24px;
    transition: all ease 0.3s;
    text-decoration: none;
    font-size: 20px;
    border-radius: 3px;
}

.homePages .business-btn {
    margin-right: 16px;
    background-color: var(--green);
    color: var(--main);
}

.homePages .business-btn:hover {
    background-color: #7df3e5;
}

.homePages .player-btn {
    margin-left: 16px;
    background-color: var(--purple);
    color: var(--white);
}

.homePages .player-btn:hover {
    background-color: #be48db;
}

.homePages svg {
    margin-left: 8px;
}

.homePages .player-btn svg {
    fill: var(--white);
}

::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1200px) {
    #canvasOne {
        width: 100vw;
        height: 70vh;
        margin-top: 10%;
        max-width: 970px;
    }
}

@media (max-width: 991px) {
    .homePages h1 {
        font-size: 42px;
    }
    .homePages h2 {
        margin-bottom: 44px;
        font-size: 24px;
    }
    .homePages .container {
        margin-top: 25%;
    }
    #canvasOne {
        width: 100vw;
        max-width: 740px;
        height: 50vw;
        margin-top: 20%;
    }
}

@media (max-width: 767px) {
    .homePages h2 {
        font-size: 18px;
    }
    .homePages h1  {
        font-size: 36px;
    }
    .homePages .container {
        margin-top: 30%;
    }
    #canvasOne {
        width: 100vw;
        max-width: 740px;
        height: 60vw;
        margin-top: 20%;
    }
    .btn-area {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .homePages .business-btn, 
    .homePages .player-btn {
        margin: 0;
    }
    .homePages .player-btn {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {

    .homePages h2 {
        font-size: 16px;
    }
    .homePages h1  {
        font-size: 32px;
    }
    .homePages .container {
        margin-top: 50%;
    }
    .homePages a {
        font-size: 16px;
    }
    #canvasOne {
        width: 100vw;
        height: 60vw;
        margin-top: 35%;
    }
}

@media (max-width: 480px) {
    #canvasOne {
        margin-top: 40%;
    }
    .homePages h2 {
        font-size: 14px;
    }
    .homePages h1  {
        font-size: 22px;
    }
}
