@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Roman.ffab5d93.eot);
    src: local('Avenir Roman'), local('Avenir-Roman'),
        url(/static/media/Avenir-Roman.ffab5d93.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Roman.016811bb.woff2) format('woff2'),
        url(/static/media/Avenir-Roman.78a48a37.woff) format('woff'),
        url(/static/media/Avenir-Roman.d7a0df99.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Light.9c3c78af.eot);
    src: local('Avenir Light'), local('Avenir-Light'),
        url(/static/media/Avenir-Light.9c3c78af.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Light.21784c28.woff2) format('woff2'),
        url(/static/media/Avenir-Light.a7696fa6.woff) format('woff'),
        url(/static/media/Avenir-Light.c888326f.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Book';
    src: url(/static/media/Avenir-BookOblique.612495b1.eot);
    src: local('Avenir Book Oblique'), local('Avenir-BookOblique'),
        url(/static/media/Avenir-BookOblique.612495b1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-BookOblique.1c3dcdd0.woff2) format('woff2'),
        url(/static/media/Avenir-BookOblique.11ff1239.woff) format('woff'),
        url(/static/media/Avenir-BookOblique.5e92bb80.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Black Oblique';
    src: url(/static/media/Avenir-BlackOblique.7fa7ae74.eot);
    src: local('Avenir Black Oblique'), local('Avenir-BlackOblique'),
        url(/static/media/Avenir-BlackOblique.7fa7ae74.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-BlackOblique.e6244f91.woff2) format('woff2'),
        url(/static/media/Avenir-BlackOblique.b611d52a.woff) format('woff'),
        url(/static/media/Avenir-BlackOblique.7577ae6f.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Heavy.066bac22.eot);
    src: local('Avenir Heavy'), local('Avenir-Heavy'),
        url(/static/media/Avenir-Heavy.066bac22.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Heavy.aea0197d.woff2) format('woff2'),
        url(/static/media/Avenir-Heavy.94a57b63.woff) format('woff'),
        url(/static/media/Avenir-Heavy.a166744b.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-HeavyOblique.1acb363f.eot);
    src: local('Avenir Heavy Oblique'), local('Avenir-HeavyOblique'),
        url(/static/media/Avenir-HeavyOblique.1acb363f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-HeavyOblique.e037514f.woff2) format('woff2'),
        url(/static/media/Avenir-HeavyOblique.4c984ad8.woff) format('woff'),
        url(/static/media/Avenir-HeavyOblique.150d51a6.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-MediumOblique.c71ada4a.eot);
    src: local('Avenir Medium Oblique'), local('Avenir-MediumOblique'),
        url(/static/media/Avenir-MediumOblique.c71ada4a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-MediumOblique.a40a5c25.woff2) format('woff2'),
        url(/static/media/Avenir-MediumOblique.2abbd8ee.woff) format('woff'),
        url(/static/media/Avenir-MediumOblique.7fc613aa.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-LightOblique.fa4f487c.eot);
    src: local('Avenir Light Oblique'), local('Avenir-LightOblique'),
        url(/static/media/Avenir-LightOblique.fa4f487c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-LightOblique.22b9bdb9.woff2) format('woff2'),
        url(/static/media/Avenir-LightOblique.d546a9cc.woff) format('woff'),
        url(/static/media/Avenir-LightOblique.b1b812aa.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Book';
    src: url(/static/media/Avenir-Book.16e7f491.eot);
    src: local('Avenir Book'), local('Avenir-Book'),
        url(/static/media/Avenir-Book.16e7f491.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Book.ca2059b5.woff2) format('woff2'),
        url(/static/media/Avenir-Book.dc54a569.woff) format('woff'),
        url(/static/media/Avenir-Book.c234b1f3.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Black.69ddcdc8.eot);
    src: local('Avenir Black'), local('Avenir-Black'),
        url(/static/media/Avenir-Black.69ddcdc8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Black.45a182ef.woff2) format('woff2'),
        url(/static/media/Avenir-Black.9557b515.woff) format('woff'),
        url(/static/media/Avenir-Black.05f89471.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Oblique.62177c18.eot);
    src: local('Avenir Oblique'), local('Avenir-Oblique'),
        url(/static/media/Avenir-Oblique.62177c18.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Oblique.46d91b0f.woff2) format('woff2'),
        url(/static/media/Avenir-Oblique.2c8a2bda.woff) format('woff'),
        url(/static/media/Avenir-Oblique.f9a00bce.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/Avenir-Medium.a8a5dcba.eot);
    src: local('Avenir Medium'), local('Avenir-Medium'),
        url(/static/media/Avenir-Medium.a8a5dcba.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Avenir-Medium.28002881.woff2) format('woff2'),
        url(/static/media/Avenir-Medium.77d901f5.woff) format('woff'),
        url(/static/media/Avenir-Medium.96223a07.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}


header {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: auto;
    display: block;
    margin-bottom: 0;
    font-family: 'Avenir', sans-serif;
    background-color: var(--main);
    z-index: 999;
}

.header-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}


.items {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: stretch;
}

.logo {
    display: flex;
    align-items: center;
    -webkit-box-align: center;
}

.logo a {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.logo img {
    margin-left: 10px;
    width: 30px;
    height: 30px;
}

nav {
    position: static;
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    align-self: auto;
    flex: 0 1 auto;
    float: right;
    transition: 0.5s ease all;
}

.nav-item {
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    color: var(--white);
    font-size: 16px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    transition: all ease 0.3s;
}

.nav-item:hover {
    color: var(--green);
}

.login-area, .signUp-area {
    display: inline-block;
    transition: all ease 0.3s;
}

.login-area a {
    border-radius: 3px;
    background-color: var(--white);
    color: var(--main);
}

.login-area a:hover {
    background-color: var(--green);
    color: var(--main);
}

.signUp-area a {
    border-style: solid;
    border-width: 1px;
    border-color: var(--white);
    border-radius: 3px;
}

.signUp-area a:hover {
    border-color: var(--green);
}

.navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 23px; 
    background-color: var(--main);
    display: none;
}

.navbar-toggler:hover {
    background-color: var(--main);
}

.navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1; 
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0; 
}

.navbar-toggler[aria-expanded="true"] span:first-child {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: relative;
    top: 7.5px; 
}

.navbar-toggler[aria-expanded="true"] span:last-child {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 6px;
    position: relative; 
}

.navbar-toggler span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer; 
}

@media (max-width: 1619px) {
    .header-container {
        max-width: 900px;
    }
}

@media (max-width: 1199px) {
    .header-container {
        max-width: 700px;
    }
}

@media (max-width: 767px) {
    .header-container {
        width: auto;
        margin: 0 30px;
    }
    .nav-menu {
        display: none;
    }
    .items {
        flex-direction: row-reverse;
    }
    .navbar-toggler {
        display: block;
    }
    nav[aria-expanded="true"] {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        position: fixed;
        top: 111px;
        left: 0;
        width: 100%;
        background-color: var(--main);
    }
    nav[aria-expanded="true"] a {
        margin: 10px 0;
        font-size: 18px;
    }
    .logo a {
        font-size: 22px;
    }
}

@media (max-width: 575px) {
    nav[aria-expanded="true"] a, nav[aria-expanded="true"] p {
        font-size: 16px;
    }
}

footer {
    margin-top: 64px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Avenir', sans-serif;
    font-weight: 300;
    border-top: 1px solid hsla(0, 0%, 100%, 0.2);
    background-color: var(--main);
}

.rs {
    padding-top: 10px;
    display: flex;
    width: 20%;
    padding-bottom: 0;
    flex-direction: column;
    align-items: center;
}

.rs-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-logo {
    margin-top: 24px;
    margin-bottom: 18px;
}

.footer-logo a {
    font-size: 22px;
    font-weight: normal;
}

.copyright {
    min-width: 137px;
    padding: 8px 0;
    font-size: 16px;
    line-height: 40px;
    text-align: right;
}

.rs-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rs-item {
    display: flex;
    width: 29px;
    height: 39px;
    margin-right: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.rs-item:hover svg {
    fill: var(--green);
}

.rs-item svg {
    vertical-align: middle;
    display: inline-block;
    transition: all ease 0.3s;
}

.footer-nav-item {
    margin-right: 32px;
    margin-left: 32px;
    padding: 20px;
}

.footer-nav-list {
    display: flex;
    flex-direction: column;
}

.footer-nav-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all ease 0.3s;
    text-align: left;
}

.default {
    cursor: default;
}

.footer-nav-item a:hover {
    color: var(--green);
}

.divider {
    height: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: hsla(0, 0%, 100%, 0.2);
}

@media (max-width: 991px) {
    .footer-nav-item {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .rs {
        width: auto;
        margin: 0 30px;
    }
    .rs-area {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .rs-area div {
        margin: 0 10px;
    }
    .footer-nav-list-item:first-child {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .footer-nav-item {
        padding-top: 0;
    }
}

@media (max-width: 480px) {
    .copyright {
        font-size: 12px;
    }
}
:root {
  --main:#16141a;
  --black: #000000;
  --white: #FFFFFF;
  --blue: #00D2FF;
  --green: #16e7cf;
  --purple: #b300de;
}

body {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  background-color: #16141a;
  background-color: var(--main);
  color: white;
}


a:active, a:hover, a {
  text-decoration: none;
  color: #FFFFFF;
  color: var(--white);
}

.container {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.playerPage, .businessPage {
  display: flex;
  justify-content: space-around;
  margin: 150px 0;
}

canvas {
  width: 55%;
  height: 75%;
}

@media (max-width:767px) {
  canvas {
      position: absolute;
      height: auto;
      width: auto;
      overflow: hidden;
      z-index: -1;
      top: 10%;
  }

}

.playerPage .entryForm,
.businessPage .entryForm {
  padding: 10px;
  border-radius: 10px;
  font-size: 22px;
  line-height: 32px;
  width: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input-box{
  position: relative;
  background-color: var(--light);
  margin-top: 285px;
  width: 757px;
  min-height: 532px;
  display: flex;
  padding: 35px;
  transition: none;
  border-radius: 25px;
  vertical-align: middle;
  align-items: center;  
  justify-content: center;
  font-size: 24px;
  line-height: 38px;
}
.privacy-policy h1 {
    font-size: 38px;
    color: hsla(0, 0%, 100%, 0.8);
    margin-bottom: 16px;
    margin-top: 20px;
    font-weight: 600;
    line-height: 44px;
}

.privacy-policy p {
    font-weight: 300;
    font-size: 19px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 10px;
}

.paragraph p {
    margin-bottom: 20px;
    font-family: 'Avenir Book', sans-serif;
    font-weight: bold;
    margin-top: 0;
}

.privacy-policy h2 {
    margin-bottom: 16px;
    color: hsla(0, 0%, 100%, 0.8);
    font-weight: 600;
    font-size: 28px;
}

.privacy-policy h3 {
    font-size: 20px;
}

.privacy-policy ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
}

.privacy-policy ul li {
    margin-bottom: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.privacy-policy .strong-ul {
    font-weight: bold;
}

.privacy-policy a {
    color: var(--green);
    text-decoration: none;
}

@media (max-width: 991px) {
    .container {
        width: 70%;
    }
    .privacy-policy h2 {
        font-size: 24px;
    }
    .privacy-policy h1 {
        font-size: 32px;
    }
    .privacy-policy p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .privacy-policy p {
        font-size: 16px;
    }
    .privacy-policy h2 {
        font-size: 22px;
    }
    .privacy-policy h1 {
        font-size: 28px;
    }
}

@media (max-width: 480px) {
    .adress {
        flex-direction: column;
    }
    .adress p {
        margin: 10px 0;
        font-size: 14px;
    }
    .about-section .container h2 {
        font-size: 22px;
        font-weight: 700;
    }
}
.general-terms h1 {
    color:hsla(0, 0%, 100%, 0.8);
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.general-terms p {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 16px;
    font-family: 'Avenir', sans-serif;
    font-weight: 300;
}

.general-terms span{
    font-weight: 700;
}

.general-terms h2 {
    margin-bottom: 24px;
    color: hsla(0, 0%, 100%, 0.8);
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .container {
        width: 70%;
        margin-top: 130px;
    }
    .general-terms h2 {
        font-size: 24px;
    }
    .general-terms h1 {
        font-size: 32px;
    }
    .general-terms p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .general-terms p {
        font-size: 16px;
    }
    .general-terms h2 {
        font-size: 22px;
    }
    .general-terms h1 {
        font-size: 22px;
    }
}
#canvasOne {
    width: 100vw;/*calc(100vh * 0.5625 * 0.8*/
    height: 80vw;
    max-height: 800px;
    max-width: 1180px;
    position: absolute;
    margin-top: 50px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    top: 0;
    z-index: -1;
}

.homePages {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.homePages .container {
    margin-top: 20%;
    margin-bottom: 31%; 
}

.homePages h1 {
    margin-bottom: 32px;
    font-size: 50px;
    text-align: center;
    line-height: 1.5;
    margin-top: 20px;
}

.homePages h2 {
    margin-top: 16px;
    margin-bottom: 64px;
    font-size: 24px;
    text-align: center;
    line-height: 1.5;
}

.homePages .btn-area {
    display: flex;
    justify-content: center;
}

.homePages a {
    padding: 16px 24px;
    transition: all ease 0.3s;
    text-decoration: none;
    font-size: 20px;
    border-radius: 3px;
}

.homePages .business-btn {
    margin-right: 16px;
    background-color: var(--green);
    color: var(--main);
}

.homePages .business-btn:hover {
    background-color: #7df3e5;
}

.homePages .player-btn {
    margin-left: 16px;
    background-color: var(--purple);
    color: var(--white);
}

.homePages .player-btn:hover {
    background-color: #be48db;
}

.homePages svg {
    margin-left: 8px;
}

.homePages .player-btn svg {
    fill: var(--white);
}

::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1200px) {
    #canvasOne {
        width: 100vw;
        height: 70vh;
        margin-top: 10%;
        max-width: 970px;
    }
}

@media (max-width: 991px) {
    .homePages h1 {
        font-size: 42px;
    }
    .homePages h2 {
        margin-bottom: 44px;
        font-size: 24px;
    }
    .homePages .container {
        margin-top: 25%;
    }
    #canvasOne {
        width: 100vw;
        max-width: 740px;
        height: 50vw;
        margin-top: 20%;
    }
}

@media (max-width: 767px) {
    .homePages h2 {
        font-size: 18px;
    }
    .homePages h1  {
        font-size: 36px;
    }
    .homePages .container {
        margin-top: 30%;
    }
    #canvasOne {
        width: 100vw;
        max-width: 740px;
        height: 60vw;
        margin-top: 20%;
    }
    .btn-area {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .homePages .business-btn, 
    .homePages .player-btn {
        margin: 0;
    }
    .homePages .player-btn {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {

    .homePages h2 {
        font-size: 16px;
    }
    .homePages h1  {
        font-size: 32px;
    }
    .homePages .container {
        margin-top: 50%;
    }
    .homePages a {
        font-size: 16px;
    }
    #canvasOne {
        width: 100vw;
        height: 60vw;
        margin-top: 35%;
    }
}

@media (max-width: 480px) {
    #canvasOne {
        margin-top: 40%;
    }
    .homePages h2 {
        font-size: 14px;
    }
    .homePages h1  {
        font-size: 22px;
    }
}

.about-section .container {
    padding-bottom: 128px;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-section h1 {
    margin-bottom: 48px;
    font-size: 38px;
    line-height: 44px;
    margin-top: 20px;
    font-weight: bold;
}

.about-section .svg-section {
    display: flex;
    margin-bottom: 36px;
    justify-content: center;
    align-items: center;
    max-height: 30px;
}

.about-section .svg-section div {
    height: 30px;
    margin-right: 8px;
}

.about-section .svg-section svg {
    max-height: 30px;
    max-width: 30px;
    fill: #676a6c;
}

.about-section .plus svg, 
.about-section .equally svg {
    max-width: 20px;
    max-height: 20px;
    padding-top: 5px;
}

.about-section .container h2 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 400;
}

.about-section .container p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 40px;
}

.about-section .maps-container {
    margin-bottom: 0px;
}

.about-section .maps-container .adress {
    padding: 16px 48px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.adress .logo {
    font-size: 22px;
    font-weight: normal;
}

.adress p {
    font-size: 18px;
    font-weight: 400;
}

.adress .mail {
    color: var(--green);
    font-size: 18px;
    text-decoration: none;
}

@media (max-width: 767px) {
    .about-section .container {
        padding-bottom: 0;
        width: 70%;
    }
    .maps-container {
        flex-direction: column;
    }
    .about-section h1 {
        font-size: 36px;
    }
    .adress p {
        font-size: 16px;
        margin: 0;
        line-height: 1.5;
    }
}

@media (max-width: 575px) {
    .about-section .container h2 {
        font-size: 24px;
    }
    .about-section h1  {
        font-size: 32px;
    }
}

@media (max-width: 480px) {
    .adress {
        flex-direction: column;
    }
    .adress p {
        margin: 10px 0;
        font-size: 14px;
    }
    .about-section .container h2 {
        font-size: 22px;
        font-weight: 700;
    }
}

.playerPage .entryForm {
    flex-direction: column;
}

.playerPage .Registration {
    display: flex;
    flex-direction: row;
}

.playerPage .Success {
    max-width: 250px;
    max-height: 48px;
    margin: 0 10px;
}

.playerPage .Success p {
    margin: 0;
    line-height: 48px;
}

.playerPage h1 {
    line-height: 36px;
    font-size: 24px;
    text-align: center;
}

.playerPage p {
    text-align: center;
}

.Registration .btn {
    display: inline-block;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 3px;
    background-color: var(--white);
}

.Registration .btn svg {
    position: absolute;
    top: 0;
    left: 8px;
    fill: var(--main);
    max-width: 40px;
    max-height: 50px;
}

.Registration .btn a {
    border-radius: 3px;
    color: var(--main);
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    padding-left: 46px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.Registration .btn:hover {
    background-color: var(--purple);
}

@media (max-width: 1199px) {
    .playerPage .Registration {
        flex-direction: column;
    }
    .Registration .btn {
        margin-top: 20px;
    }
    .playerPage h1 {
        margin-bottom: 0;

    }
}

@media (max-width: 767px) {
    .playerPage {
        margin: 30% 0;
    }
}

@media (max-width: 575px) {
    .playerPage {
        margin: 40% 0;
    }
}

@media (max-width: 480px) {
    .playerPage h1 {
        font-size: 22px;
    }
}

.businessPage .entryForm {
    color: var(--white);
}

.businessPage form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;    
    width: 500px;
}

.businessPage h1{
    line-height: 28px;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}

.businessPage form .title {
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 5px;
    position: relative;
    margin-top: 0;
}

.businessPage .area{
    margin-bottom: 20px;
}

input{
    display: block;
    height: 38px;
    padding: 0 0.75rem;
    width: 100%;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5;
    color: black;
    background-clip: padding-box;
    border: 2px solid #BBBBBB;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input::-webkit-input-placeholder {
    color: black;
    opacity: 0.4;
}

input:-ms-input-placeholder {
    color: black;
    opacity: 0.4;
}

input::placeholder {
    color: black;
    opacity: 0.4;
}

input:focus {
    color: black;
    border-color: var(--green);
    outline: 0;
}

input:disabled,
input[readonly] {
    background-color: #f5f5f5;
    opacity: 1;
}

.SendButton {
    display: inline-block;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--white);
    padding: 0;
    border: none;
}

.SendButton p {
    border-radius: 3px;
    color: var(--main);
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

button:hover{
    background-color: var(--green);
}

#upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all ease 0.3s;
    cursor: pointer;
    min-width: 240px;
    border-radius: 3px;
    background-color: var(--white);
}

#upload-container p{
    color: var(--main);
    padding: 8px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

#upload-container svg {
    position: relative;
    fill: var(--main);
    max-width: 40px;
    max-height: 40px;
}

#upload-container img {
    position: relative;
    max-width: 30px;
    max-height: 30px;
}

#upload-container:hover {
    background-color: var(--green);
}

#upload-container input[type=file] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -10;
}

.upload, .btn {
    display: flex;
    justify-content: center;
}



.slider {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.slider-item {
    width: 60px;
    height: 70px;
    line-height: 70px;
    margin: auto 15px;
    margin-top: 5px;
    background-size: cover;
    float: left;
    flex-grow: 0;
    flex-shrink:0;
}

.item-img {
    width: 53px;
    height: 44px;
    position: relative;
    background-image: url(/static/media/CSGOlogo.09192cb2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
}

.item-fileName {
    position: relative;
    font-size: 10px;
    line-height: 24px;
    max-width: 80px;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 1px;
}

.slider-area {  
    display: flex;
    overflow-y: hidden;
}

.item-img .cross svg {
    position: absolute;
    right: -3px;
    top: -7px;
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;
}

.message {
    color: var(--green);
    line-height: 1.5;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width: 1619px) {
    .businessPage .entryForm {
        width: 400px;
    }
    .slider {
        max-width: 360px;
    }
}

@media (max-width: 991px) {
    .businessPage form .title {
        font-size: 16px;
    }
    #upload-container p {
        font-size: 14px;
    }
    #upload-container {
        min-width: 200px;
    }
    input {
        width: 300px;
    }
    .slider {
        margin: 0;
        max-width: 320px;
    }
    canvas {
        margin: auto 0;
    }
    .businessPage {
        margin-top: 170px;
    }
    .businessPage .entryForm {
        width: 320px;
        margin: 30px;
        padding: 0;
    }
    .businessPage form {
        padding: 0;
    }
    .businessPage .entryForm .Success{
        font-size: 16px;
        line-height: 1.5;
    }

}

@media (max-width: 767px) {
    .businessPage .entryForm .Success{
        text-align: center;
        margin: 80px 0;
    }
}

@media (max-width: 480px) {
    .businessPage form {
        width: 270px;
    }
    input {
        width: 240px;
    }
}
