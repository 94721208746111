.privacy-policy h1 {
    font-size: 38px;
    color: hsla(0, 0%, 100%, 0.8);
    margin-bottom: 16px;
    margin-top: 20px;
    font-weight: 600;
    line-height: 44px;
}

.privacy-policy p {
    font-weight: 300;
    font-size: 19px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 10px;
}

.paragraph p {
    margin-bottom: 20px;
    font-family: 'Avenir Book', sans-serif;
    font-weight: bold;
    margin-top: 0;
}

.privacy-policy h2 {
    margin-bottom: 16px;
    color: hsla(0, 0%, 100%, 0.8);
    font-weight: 600;
    font-size: 28px;
}

.privacy-policy h3 {
    font-size: 20px;
}

.privacy-policy ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
}

.privacy-policy ul li {
    margin-bottom: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.privacy-policy .strong-ul {
    font-weight: bold;
}

.privacy-policy a {
    color: var(--green);
    text-decoration: none;
}

@media (max-width: 991px) {
    .container {
        width: 70%;
    }
    .privacy-policy h2 {
        font-size: 24px;
    }
    .privacy-policy h1 {
        font-size: 32px;
    }
    .privacy-policy p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .privacy-policy p {
        font-size: 16px;
    }
    .privacy-policy h2 {
        font-size: 22px;
    }
    .privacy-policy h1 {
        font-size: 28px;
    }
}

@media (max-width: 480px) {
    .adress {
        flex-direction: column;
    }
    .adress p {
        margin: 10px 0;
        font-size: 14px;
    }
    .about-section .container h2 {
        font-size: 22px;
        font-weight: 700;
    }
}