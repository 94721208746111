footer {
    margin-top: 64px;
    padding-bottom: 32px;
    display: flex;
    -webkit-box-pack: start;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Avenir', sans-serif;
    font-weight: 300;
    border-top: 1px solid hsla(0, 0%, 100%, 0.2);
    background-color: var(--main);
}

.rs {
    padding-top: 10px;
    display: flex;
    width: 20%;
    padding-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.rs-area {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-items: flex-end;
}

.footer-logo {
    margin-top: 24px;
    margin-bottom: 18px;
}

.footer-logo a {
    font-size: 22px;
    font-weight: normal;
}

.copyright {
    min-width: 137px;
    padding: 8px 0;
    font-size: 16px;
    line-height: 40px;
    text-align: right;
}

.rs-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rs-item {
    display: flex;
    width: 29px;
    height: 39px;
    margin-right: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.rs-item:hover svg {
    fill: var(--green);
}

.rs-item svg {
    vertical-align: middle;
    display: inline-block;
    transition: all ease 0.3s;
}

.footer-nav-item {
    margin-right: 32px;
    margin-left: 32px;
    padding: 20px;
}

.footer-nav-list {
    display: flex;
    flex-direction: column;
}

.footer-nav-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all ease 0.3s;
    text-align: left;
}

.default {
    cursor: default;
}

.footer-nav-item a:hover {
    color: var(--green);
}

.divider {
    height: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: hsla(0, 0%, 100%, 0.2);
}

@media (max-width: 991px) {
    .footer-nav-item {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .rs {
        width: auto;
        margin: 0 30px;
    }
    .rs-area {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .rs-area div {
        margin: 0 10px;
    }
    .footer-nav-list-item:first-child {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .footer-nav-item {
        padding-top: 0;
    }
}

@media (max-width: 480px) {
    .copyright {
        font-size: 12px;
    }
}